import { createContext, useContext, useEffect, useState } from 'react'
import { useScript } from 'usehooks-ts'
import { useAccessToken } from '../hooks/GoogleCalendar'

const gapiContext = createContext<boolean>(false)

export const useGapiIsReady = () => {
  return useContext(gapiContext)
}

const gapiConfig = {
  apiKey: process.env.API_GOOGLE_APIKEY ?? '',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
}

export function GapiProvider({ children }: { children: React.ReactNode }) {
  const status = useScript('https://apis.google.com/js/api.js', {
    removeOnUnmount: false,
  })
  const [isReady, setIsReady] = useState<boolean>(false)
  const { data: accessToken } = useAccessToken()

  useEffect(() => {
    if (status === 'ready' && accessToken) {
      gapi.load('client', () => {
        void gapi.client.init(gapiConfig).then(() => {
          // eslint-disable-next-line no-console
          console.log('gapi client loaded')
          gapi.client.setToken({ access_token: accessToken?.token })
          // console.log(gapi.client.getToken());
          setIsReady(true)
        })
      })
    }
  }, [status, accessToken])

  return <gapiContext.Provider value={isReady}>{children}</gapiContext.Provider>
}
