import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNotification } from './NotificationProvider'

const NetworkContext = createContext<boolean>(true)

export function useIsOnline() {
  return useContext(NetworkContext)
}

export function NetworkProvider({ children }: { children: React.ReactNode }) {
  // https://tanstack.com/query/latest/docs/framework/react/guides/migrating-to-v5#network-status-no-longer-relies-on-the-navigatoronline-property
  // navigator.onLine is not reliable, especially in Chromium based browsers
  const [isOnline, setIsOnline] = useState<boolean>(true)
  const { showNotification, hideNotification } = useNotification()

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
      // console.debug('online')
      hideNotification()
    }
    const handleOffline = () => {
      setIsOnline(false)
      // console.debug('offline')
      showNotification({
        title: 'Editor is offline',
        message: "Additional changes won't be saved.",
        autoHide: false,
        icon: 'fa-triangle-exclamation text-yellow-400',
      })
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NetworkContext.Provider value={isOnline}>
      {children}
    </NetworkContext.Provider>
  )
}
