import dayjs from 'dayjs'
import { getGraphClient } from './graphClient'
import { Calendar, Event } from '@microsoft/microsoft-graph-types'
import {
  GraphRequestOptions,
  PageIterator,
} from '@microsoft/microsoft-graph-client'

type CalendarResponse = {
  '@odata.context': string
  value: Calendar[]
}

export async function fetchAvailableCalendars() {
  const graphClient = getGraphClient()
  const response = (await graphClient
    .api('/me/calendars')
    .get()) as CalendarResponse
  return response.value
}

type CalendarEventResponse = {
  '@odata.context': string
  '@odata.nextLink': string
  value: Event[]
}

/**
 * Fetches calendar events for a specific calendar and day.
 *
 * @param {string} calendarId - The ID of the calendar to fetch events from.
 * @param {string} day - The day for which to fetch events, in YYYY-MM-DD format.
 * @returns {Promise<Event[]>} - A promise that resolves to an array of calendar events.
 */
export async function fetchCalendarEvents(calendarId: string, day: string) {
  const graphClient = getGraphClient()
  const browserTimezone = dayjs.tz.guess()
  const startDateTime = encodeURIComponent(
    dayjs(day).startOf('day').tz(browserTimezone).format()
  )
  const endDateTime = encodeURIComponent(
    dayjs(day).endOf('day').tz(browserTimezone).format()
  )

  // see https://learn.microsoft.com/en-us/graph/api/calendar-list-calendarview?view=graph-rest-1.0&tabs=http#http-request
  // see https://learn.microsoft.com/en-us/graph/api/calendar-list-calendarview?view=graph-rest-1.0&tabs=http#request-headers
  // see https://learn.microsoft.com/en-us/graph/api/calendar-list-calendarview?view=graph-rest-1.0&tabs=http#query-parameters
  const response = (await graphClient
    .api(`/me/calendars/${calendarId}/calendarview`)
    .header('Prefer', `outlook.timezone="${browserTimezone}"`)
    .query({ startDateTime, endDateTime })
    .get()) as CalendarEventResponse
  if (response['@odata.nextLink']) {
    const events: Event[] = []

    // Must include the time zone header in page requests too
    const options: GraphRequestOptions = {
      headers: {
        Prefer: `outlook.timezone="${browserTimezone}"`,
      },
    }
    const pageIterator = new PageIterator(
      graphClient,
      response,
      (event) => {
        events.push(event as Event)
        return true
      },
      options
    )
    await pageIterator.iterate()
    return events
  } else {
    return response.value
  }
}
