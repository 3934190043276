import { Client } from '@microsoft/microsoft-graph-client'
import { getAuthProvider } from './authProvider'

let graphClient: Client | undefined

export const getGraphClient = () => {
  if (!graphClient) {
    const authProvider = getAuthProvider()
    graphClient = Client.initWithMiddleware({
      authProvider,
    })
  }
  return graphClient
}
