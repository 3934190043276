import { createContext, useContext } from 'react'
import { DefaultBlockSchema, PartialBlock } from '@packages/blocknote-core'

// Types
export type NoteReferenceMap = Map<string, IncomingReferences> // key: date or recordName or title of a non-existing note

export type IncomingReferences = Map<string, FromNote> // key: recordName

export type FromNote = {
  title: string
  blocks: PartialBlock<DefaultBlockSchema>[]
}

// Context
export const ReferencesContext = createContext<NoteReferenceMap | undefined>(
  undefined
)

export function useReferences() {
  const context = useContext(ReferencesContext)
  if (!context) {
    throw new Error('useReferences must be used within a ReferencesProvider')
  }
  return context
}
