import { Note, NoteType, isTeamspaceNote } from './syncUtils'

export function getTeamSpace(
  teamProjectNotesMap: Map<string, Note> | undefined,
  note: Note
): Note | undefined {
  if (!teamProjectNotesMap) {
    return
  }
  if (!isTeamspaceNote(note.noteType)) {
    return
  }
  if (note.noteType === NoteType.TEAM_SPACE) {
    return note
  }
  const parentNote = note.parent
    ? teamProjectNotesMap.get(note.parent)
    : undefined
  return parentNote ? getTeamSpace(teamProjectNotesMap, parentNote) : undefined
}

export function getTeamSpaceTitle(
  teamProjectNotesMap: Map<string, Note>,
  note: Note
) {
  const teamSpace = getTeamSpace(teamProjectNotesMap, note)
  return teamSpace ? teamSpace.title : undefined
}
