import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { InteractionType } from '@azure/msal-browser'
import { authScopes } from './authConfig'
import { msalInstance } from './authConfig'

export function getAuthProvider() {
  let activeAccount = msalInstance.getActiveAccount()
  if (!activeAccount) {
    // eslint-disable-next-line unicorn/no-null -- because getActiveAccount() returns null if there are no accounts
    activeAccount = msalInstance.getAllAccounts().at(0) ?? null
    msalInstance.setActiveAccount(activeAccount)
  }
  if (!activeAccount) {
    throw new Error('No active account')
  }
  const options: AuthCodeMSALBrowserAuthenticationProviderOptions = {
    ...authScopes,
    account: activeAccount,
    interactionType: InteractionType.Popup,
  }

  return new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, options)
}
