import { MenuItem } from '@szhsin/react-menu'
import { ReactNode } from 'react'
import { ExternalCalendar } from '../../../utils/calendar'
import { ExternalCalendarList } from './ExternalCalendarList'

type CalendarSectionProps = {
  title: string
  calendars: ExternalCalendar[]
  isSignedIn: boolean
  onSignIn: () => void
  onSignOut: () => void
  dialog?: ReactNode // e.g. <SignInAppleDialog />
}

export function ExternalCalendarSection({
  title,
  calendars,
  isSignedIn,
  onSignIn,
  onSignOut,
  dialog,
}: CalendarSectionProps) {
  return (
    <>
      {/* optional sign-in dialog for Apple */}
      {dialog}

      {isSignedIn ? (
        <>
          <MenuItem disabled>{title}</MenuItem>
          <ExternalCalendarList items={calendars} />
          <MenuItem onClick={onSignOut}>
            <i
              className='fa-regular fa-calendar-minus h-6 w-6 text-sm'
              aria-hidden='true'
            />
            Sign out of {title}
          </MenuItem>
        </>
      ) : (
        <MenuItem onClick={onSignIn}>
          <i
            className='fa-regular fa-calendar-plus h-6 w-6 text-sm'
            aria-hidden='true'
          />
          Sign in with {title}
        </MenuItem>
      )}
    </>
  )
}
