import { Menu, MenuButton, MenuDivider, MenuHeader } from '@szhsin/react-menu'
import { useIsAuthenticated } from '@azure/msal-react'
import { useAppleCalendars } from '../../hooks/AppleCalDAV'
import { useAccessToken } from '../../hooks/GoogleCalendar'
import { OutlookCalendarSection } from './components/OutlookCalendarSection'
import { GoogleCalendarSection } from './components/GoogleCalendarSection'
import { AppleCalendarSection } from './components/AppleCalendarSection'

export function CalendarMenu() {
  const { data: accessToken } = useAccessToken()
  const { data: appleCalendars = [] } = useAppleCalendars()
  const hasAppleCalendars =
    Array.isArray(appleCalendars) && appleCalendars.length > 0

  const isGoogleSignedIn = !!accessToken?.token
  const isAppleSignedIn = hasAppleCalendars
  const isMsSignedIn = useIsAuthenticated()
  const menuIcon =
    isGoogleSignedIn || isAppleSignedIn || isMsSignedIn
      ? 'fa-regular fa-calendar-check'
      : 'fa-regular fa-calendar-plus'

  return (
    <Menu
      menuButton={
        <MenuButton className='p-1 hover:bg-gray-500/25'>
          <i className={`${menuIcon} h-6 w-6 text-sm`} aria-hidden='true' />
        </MenuButton>
      }
    >
      <MenuHeader>Manage Calendars</MenuHeader>
      <MenuDivider />
      <AppleCalendarSection />
      <MenuDivider />
      <GoogleCalendarSection />
      <MenuDivider />
      <OutlookCalendarSection />
    </Menu>
  )
}

// type SettingsMenuProps = { timelineDays: number; onSetTimelineDays: (_days: number) => void };
// const SettingsMenu = ({ timelineDays, onSetTimelineDays }: SettingsMenuProps) => {
//   return (
//     <Menu
//       className=""
//       menuButton={
//         <MenuButton className="p-1 hover:bg-gray-500/25">
//           <i className="fa-regular fa-gear h-5 w-5 text-sm" aria-hidden="true" />
//         </MenuButton>
//       }
//     >
//       <MenuRadioGroup value={timelineDays} onRadioChange={(e) => onSetTimelineDays(e.value)}>
//         <MenuHeader>Timeline</MenuHeader>
//         <MenuItem type="radio" value={1}>
//           1 day
//         </MenuItem>
//         <MenuItem type="radio" value={2}>
//           2 days
//         </MenuItem>
//         <MenuItem type="radio" value={3}>
//           3 days
//         </MenuItem>
//         <MenuItem type="radio" value={4}>
//           4 days
//         </MenuItem>
//         <MenuItem type="radio" value={5}>
//           5 days
//         </MenuItem>
//         <MenuItem type="radio" value={6}>
//           6 days
//         </MenuItem>
//         <MenuItem type="radio" value={7}>
//           7 days
//         </MenuItem>
//       </MenuRadioGroup>
//     </Menu>
//   );
