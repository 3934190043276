import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
import { trackError } from '../analytics'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    // Get the client ID from https://portal.azure.com (it's called Application ID here).
    // Search for "App Registrations", select "All applications", and you should see "NotePlan" in the list.
    clientId: 'ce913928-16a2-4139-8943-fb2bd0eb3522',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: import.meta.env.DEV
      ? 'http://localhost:3000/'
      : 'https://app.noteplan.co/',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error: {
            console.error(message)
            return
          }
          case LogLevel.Info: {
            // eslint-disable-next-line no-console
            console.info(message)
            return
          }
          case LogLevel.Verbose: {
            // eslint-disable-next-line no-console
            console.debug(message)
            return
          }
          case LogLevel.Warning: {
            // eslint-disable-next-line no-console
            console.warn(message)
            return
          }
          default: {
            return
          }
        }
      },
    },
  },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const authScopes = {
  scopes: ['User.Read', 'Calendars.Read'],
}

export const msalInstance = new PublicClientApplication(msalConfig)

export function signin() {
  msalInstance.loginPopup(authScopes).catch((error: unknown) => {
    trackError('signin', 'Can not sign in with Microsoft', {
      error,
    })
  })
}

export function signout() {
  void msalInstance.logoutPopup({
    postLogoutRedirectUri: '/',
  })
}
