import { MenuItem } from '@szhsin/react-menu'
import { ExternalCalendar } from '../../../utils/calendar'
import { useLocalStorage } from 'usehooks-ts'

type ExternalCalendarListProps = {
  items: ExternalCalendar[]
}

export function ExternalCalendarList({ items }: ExternalCalendarListProps) {
  const [hiddenCalendars, setHiddenCalendars] = useLocalStorage<string[]>(
    'hiddenCalendars',
    []
  )

  function handleCalendarToggle(calendarId: string) {
    setHiddenCalendars((previous) => {
      if (previous.includes(calendarId)) {
        return previous.filter((id) => id !== calendarId)
      }
      return [...previous, calendarId]
    })
  }

  return (
    <>
      {items.map((calendar) => (
        <MenuItem
          key={calendar.id}
          type='checkbox'
          checked={!hiddenCalendars.includes(calendar.id)}
          onClick={() => {
            handleCalendarToggle(calendar.id)
          }}
        >
          <div className='flex items-center gap-2'>
            <div
              className='relative flex h-4 w-4 items-center justify-center'
              style={{ backgroundColor: calendar.color }}
            >
              {!hiddenCalendars.includes(calendar.id) && (
                <i className='fa-solid fa-check text-sm text-white' />
              )}
            </div>
            <span>{calendar.name}</span>
          </div>
        </MenuItem>
      ))}
    </>
  )
}
