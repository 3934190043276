import { useQueries, useQuery } from '@tanstack/react-query'
import { cacheKeys } from '../utils/queryKeyFactory'
import { useAuthenticatedUser } from '../providers/UserProvider'
import { cachedNotesContext } from '../providers/CachedNotesProvider'
import { useIsAuthenticated } from '@azure/msal-react'
import {
  fetchAvailableCalendars,
  fetchCalendarEvents,
} from '../lib/msal/graphService'
import { CalendarSource, ExternalCalendar } from '../utils/calendar'
import { DEFAULT_CALENDAR_COLOR } from '../utils/color'
import dayjs, { Dayjs } from 'dayjs'
import { CalendarEvent } from '../modules/calendar/CalendarEvent'
import { calculateEventPosition } from '../modules/calendar/timeline/utils/event'
import { Event } from '@microsoft/microsoft-graph-types'

export function useOutlookCalendars() {
  const user = useAuthenticatedUser()
  const isSignedIn = useIsAuthenticated()

  return useQuery({
    enabled: isSignedIn,
    context: cachedNotesContext,
    staleTime: 0, // make this immediately stale so the following refetches will trigger
    refetchInterval: 5000 * 60, // every 5 minute
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: 1, // once is enough
    queryKey: cacheKeys.outlookCalendars(user.userId),
    queryFn: fetchAvailableCalendars,
    select: (data) => {
      return data.map(
        (calendar) =>
          ({
            id: calendar.id,
            name: calendar.name,
            color:
              calendar.hexColor === ''
                ? DEFAULT_CALENDAR_COLOR
                : calendar.hexColor,
            source: CalendarSource.Outlook,
          }) as ExternalCalendar
      )
    },
  })
}

export function useOutlookEvents(selectedDay: Dayjs) {
  const user = useAuthenticatedUser()
  const isSignedIn = useIsAuthenticated()
  const day = selectedDay.format('YYYY-MM-DD') // stick to ISO 8601 default dayjs format for easier conversion
  const { data: calendars = [] } = useOutlookCalendars()

  return useQueries({
    context: cachedNotesContext,
    queries: calendars.map((calendar) => ({
      enabled: isSignedIn,
      staleTime: 0, // make this immediately stale so the following refetches will trigger
      refetchInterval: 5000 * 60, // every 5 minute
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 1, // once is enough
      queryKey: cacheKeys.outlookEvents(user.userId, calendar.id, day),
      queryFn: () => fetchCalendarEvents(calendar.id, day),
      select: (data: Event[]) => {
        return data
          .map((event) => {
            return {
              id: event.id,
              contentType: 'event',
              content: event.subject,
              ...(event.start?.dateTime &&
                event.end?.dateTime && {
                  startDate: dayjs(event.start.dateTime),
                  endDate: dayjs(event.end.dateTime),
                  position: calculateEventPosition(
                    dayjs(event.start.dateTime),
                    dayjs(event.end.dateTime),
                    selectedDay
                  ),
                }),
              allDay: event.isAllDay,
              description: event.body?.content,
              color: calendar.color,
              calendarId: calendar.id,
              calendarName: calendar.name,
            }
          })
          .filter((event) => Boolean(event.position))
          .map((event) => event as CalendarEvent)
      },
    })),
  })
}
