import { type QueryKey } from '@tanstack/react-query'
import { NoteType, isCalendarNote, isTeamspaceNote } from './syncUtils'
import { AuthType, AuthenticatedUser } from './User'

function key(base: string[], ...optional: (string | AuthType | undefined)[]) {
  return [...base, ...optional.filter(Boolean)] as QueryKey
}

// privateKeys
// 0: private, calendar, $filename
// 7: private, calendar, $filename
// 1: private, project, $recordName
// 5: private, project, $recordName
// references: private, references
// logincheck: private, logincheck
export const privateKeys = {
  notes: key(['private', 'notes']),
  references: key(['private', 'references']),
  logincheck: (userId?: string) => key(['private', 'logincheck'], userId),
  zones: key(['private', 'zones']),
}

// teamKeys
// 10: team, $teamspaceId
// 12: team, $teamspaceId, calendar, $filename
// 11: team, project, $recordName
// members: team, $teamspaceId, members
// references: team, references
export const teamKeys = {
  space: (teamspaceId: string) => key(['team', teamspaceId]),
  members: (teamspaceId: string) => key(['team', teamspaceId, 'members']),
  all: key(['team']),
  references: key(['team', 'references']),
}

export type NoteQueryKeyProps = {
  noteType: NoteType
  filename: string
  recordName?: string
  parent?: string | undefined | null
}

export function noteQueryKey(config: NoteQueryKeyProps) {
  switch (config.noteType) {
    case NoteType.CALENDAR_NOTE:
    case NoteType.ASSET_CALENDAR_NOTE: {
      return key(['private', 'calendar', config.filename])
    }

    case NoteType.PROJECT_NOTE:
    case NoteType.ASSET_PROJECT_NOTE: {
      if (!config.recordName) {
        throw new Error('recordName is required for project notes')
      }
      return key(['private', 'project', config.recordName])
    }

    case NoteType.TEAM_SPACE: {
      if (!config.parent) {
        throw new Error('parent is required for team notes')
      }
      return key(['team', config.parent])
    }

    case NoteType.TEAM_SPACE_NOTE: {
      if (!config.recordName) {
        throw new Error('recordName is required for project notes')
      }
      return key(['team', 'project', config.recordName])
    }

    case NoteType.TEAM_SPACE_CALENDAR_NOTE: {
      if (!config.parent) {
        throw new Error('parent is required for team calendar notes')
      }
      return key(['team', config.parent, 'calendar', config.filename])
    }

    default: {
      throw new Error('Invalid note type')
    }
  }
}

export function projectNoteQueryKey({
  recordName,
  noteType,
}: {
  recordName: string
  noteType: NoteType
}) {
  if ([NoteType.ASSET_PROJECT_NOTE, NoteType.PROJECT_NOTE].includes(noteType)) {
    return key(['private', 'project', recordName])
  }
  if (noteType === NoteType.TEAM_SPACE_NOTE) {
    return key(['team', 'project', recordName])
  }
  if (noteType === NoteType.TEAM_SPACE) {
    return key(['team', recordName])
  }
  throw new Error('Invalid note type')
}

// cacheKeys
// extension: cache, extension
// notes: cache, notes
// privateNotes: cache, notes, private, $userId
// privateCalendarNotes: cache, notes, private, $userId, calendar
// privateProjectNotes: cache, notes, private, $userId, project
// teamNotes: cache, notes, team, $userId
// teamCalendarNotes: cache, notes, team, $userId, calendar
// teamProjectNotes: cache, notes, team, $userId, project
// appleCalendars: cache, calendars, apple, $userId
// appleEvents: cache, calendar, apple, $userId, $url, $date
export const cacheKeys = {
  extension: (authType: AuthType) => key(['extension'], authType),
  notes: key(['cache', 'notes']),
  privateNotes: (userId?: string) => key(['cache', 'notes', 'private'], userId),
  privateCalendarNotes: (userId: string) =>
    key(['cache', 'notes', 'private', userId, 'calendar']),
  privateProjectNotes: (userId: string) =>
    key(['cache', 'notes', 'private', userId, 'project']),
  teamNotes: (userId?: string) => key(['cache', 'notes', 'team'], userId),
  teamCalendarNotes: (userId: string) =>
    key(['cache', 'notes', 'team', userId, 'calendar']),
  teamProjectNotes: (userId: string) =>
    key(['cache', 'notes', 'team', userId, 'project']),
  appleCalendars: (userId: string) =>
    key(['cache', 'calendars', 'apple', userId]),
  appleEvents: (userId: string, url: string, month: string) =>
    key(['cache', 'calendars', 'apple', userId, url, month]),
  outlookCalendars: (userId: string) =>
    key(['cache', 'calendars', 'outlook', userId]),
  outlookEvents: (userId: string, calendarId: string, day: string) =>
    key(['cache', 'calendars', 'outlook', userId, calendarId, day]),
}

export function cacheKeyFromNoteType(
  noteType: NoteType,
  user: AuthenticatedUser
) {
  switch (user.authType) {
    case AuthType.CLOUDKIT: {
      return isCalendarNote(noteType)
        ? cacheKeys.privateCalendarNotes(user.userId)
        : cacheKeys.privateProjectNotes(user.userId)
    }
    case AuthType.SUPABASE: {
      return isTeamspaceNote(noteType)
        ? isCalendarNote(noteType)
          ? cacheKeys.teamCalendarNotes(user.userId)
          : cacheKeys.teamProjectNotes(user.userId)
        : isCalendarNote(noteType)
          ? cacheKeys.privateCalendarNotes(user.userId)
          : cacheKeys.privateProjectNotes(user.userId)
    }
    case AuthType.CLOUDKIT_SUPABASE: {
      return isTeamspaceNote(noteType)
        ? isCalendarNote(noteType)
          ? cacheKeys.teamCalendarNotes(user.teamUserId)
          : cacheKeys.teamProjectNotes(user.teamUserId)
        : isCalendarNote(noteType)
          ? cacheKeys.privateCalendarNotes(user.userId)
          : cacheKeys.privateProjectNotes(user.userId)
    }
  }
}
