import { type Dayjs } from 'dayjs'
import { type CalendarEvent } from '../../CalendarEvent'
import { useGoogleCalendarEvents } from '../../../../hooks/GoogleCalendar'
import { useDarkMode } from '../../../../providers/DarkModeProvider'
import {
  addAlphaToColor,
  darkenColor,
  desaturateColor,
} from '../../../../utils/color'
import { useAppleEvents } from '../../../../hooks/AppleCalDAV'
import { useMemo } from 'react'
import classNames from 'classnames'
import { useLocalStorage } from 'usehooks-ts'
import { useOutlookEvents } from '../../../../hooks/OutlookCalendar'

export function AllDayEvents({
  selectedDay,
  labelWidth,
}: {
  selectedDay: Dayjs
  labelWidth: number
}) {
  const isDarkMode = useDarkMode()
  const [hiddenCalendars] = useLocalStorage<string[]>('hiddenCalendars', [])

  const googleResults = useGoogleCalendarEvents(selectedDay)
  const googleEvents = googleResults.flatMap((result) => result.data ?? [])

  const appleResults = useAppleEvents(selectedDay)
  const appleEvents = useMemo(() => {
    return appleResults
      .flatMap((result) => result.data ?? [])
      .filter((event) => {
        return (
          event.startDate.isSame(selectedDay, 'day') ||
          event.endDate.isSame(selectedDay, 'day') ||
          (event.startDate.isBefore(selectedDay, 'day') &&
            event.endDate.isAfter(selectedDay, 'day'))
        )
      })
  }, [appleResults, selectedDay])

  const outlookResults = useOutlookEvents(selectedDay)
  const outlookEvents = outlookResults
    .flatMap((result) => result.data ?? [])
    .filter((event) => {
      // the event is leaking into the next day, so we need to filter it outj
      const realEndDate = event.endDate.subtract(1, 'day')

      return (
        event.startDate.isSame(selectedDay, 'day') ||
        (event.startDate.isBefore(selectedDay, 'day') &&
          (realEndDate.isAfter(selectedDay, 'day') ||
            realEndDate.isSame(selectedDay, 'day')))
      )
    })

  const allDayEvents: CalendarEvent[] = useMemo(() => {
    return [...googleEvents, ...appleEvents, ...outlookEvents].filter(
      (event) =>
        !hiddenCalendars.includes(event.calendarId ?? '') && event.allDay
    )
  }, [googleEvents, appleEvents, outlookEvents, hiddenCalendars])

  return (
    <div className='flex border-b-2'>
      <div
        className='flex flex-shrink-0 text-xs leading-tight text-gray-400'
        style={{ width: `${labelWidth.toString()}px` }}
      >
        <div className='w-full self-end pb-0.5 pr-2 text-right'>all-day</div>
      </div>
      <div className='grid flex-auto grid-cols-1 grid-rows-1 pt-0.5'>
        {allDayEvents.map((event) => {
          return (
            <div
              key={event.id}
              className={classNames(
                'mb-0.5 truncate rounded-md px-2 text-xs font-semibold',
                event.color ? '' : 'event-content-background-blue'
              )}
              style={{
                backgroundColor: addAlphaToColor(event.color, 0.22),
                color: event.color
                  ? darkenColor(
                      desaturateColor(event.color, 30),
                      isDarkMode ? -30 : 50
                    )
                  : 'gray',
              }}
            >
              {event.content}
            </div>
          )
        })}
      </div>
    </div>
  )
}
