import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export enum CalendarSource {
  Google = 'google',
  Apple = 'apple',
  Outlook = 'outlook',
}

export type ExternalCalendar = {
  id: string
  name: string
  color: string
  source: CalendarSource
}
