import {
  BlockSchema,
  PartialBlock,
  PropSchema,
  Props,
} from '../../extensions/Blocks/api/blockTypes'
import { DefaultBlockSchema } from '../..'
import { PartialInlineContent } from '../../extensions/Blocks/api/inlineContentTypes'
import Tokenizr from 'tokenizr'
import { isFilenameImage } from '../../extensions/Blocks/inline/inlineAttachment'

const encodedTablePrefix = '%%%npTableData:'
const encodedCodeFencePrefix = '%%%npCodeFence:'

// The attachments fetched from CloudKit. The attachments are sorted in the same order as they appear in the markdown.
// However, images are sorted before other types of files, so we need to count all images first, so we get the right attachment index.
var attachmentsList: any[] = []
var attachmentIndex: number = 0
var attachedNonImageIndex: number = 0
const imageLinkRegex = /!\[(image)\]\(([^()]+)\)/g // For counting the number of images in the markdown

type BlockType = keyof DefaultBlockSchema

const parseFunctions = [
  parseTable,
  parseCodeFence,
  parseHeader1,
  parseHeader2,
  parseHeader3,
  parseHeader4,
  parseQuote,
  parseSeparator,
  parseToDoDash,
  parseToDoDashComplete,
  parseToDoDashCancelled,
  parseToDoDashScheduled,
  parseToDoAsteriskComplete,
  parseToDoAsteriskCancelled,
  parseToDoAsteriskScheduled,
  parseToDoAsterisk,
  parseChecklistPlusComplete,
  parseChecklistPlusCancelled,
  parseChecklistPlusScheduled,
  parseChecklistPlus,
  parseOrderedList,
]

function createBlock(
  type: BlockType,
  innerText: string,
  props?: Props<PropSchema>
): PartialBlock<DefaultBlockSchema> {
  let block: PartialBlock<DefaultBlockSchema> = {
    type: type,
    content: [],
    children: [],
  }

  let folded = innerText.endsWith(' …')

  if (folded) {
    innerText = innerText.replace(/ …$/, '')
    if (props) {
      props.folded = folded
    } else {
      props = { folded: folded }
    }
  }

  if (type !== 'codefence') {
    try {
      block.content = createInlineContent(innerText)
    } catch (error) {
      block.content = innerText
      console.error(
        'Failed to tokenize (parse inline content), just setting the plain content.',
        error
      )
    }
  } else {
    block.content = innerText
  }

  if (props) {
    return { ...block, props: props }
  } else {
    return block
  }
}

function createInlineContent(text: string): PartialInlineContent[] {
  const dateLinkRegex =
    /([>@](today|tomorrow|yesterday|((\d{4})(-((0[1-9]|1[0-2])(-(0[1-9]|[12]\d|3[0-1]))?|Q[1-4]|W0[1-9]|W[1-4]\d|W5[0-3]))?)))/
  const wikilinkRegex = /(\[{2}(?:[^[\]]*?)\]{2})/
  const attachmentLinkRegex = /!\[([^[\]]*)\]\(([^()]+)\)/
  const namedLinkRegex = /\[([^[\]]*)\]\(([^()]+)\)/
  const linkRegex =
    /(\b([0-9a-zA-Z\-.+]+):\/\/[^\s{}[\]<>±„"“]+(?<![.,;"\]*]))|[^:*\s{}()[\]<>±„"“]+\.(com(\.[a-zA-Z]{2})?|dev|org|edu|gov|uk|net|in|co\.in|co\.uk|co|cn|ca|de|jp|fr|au|us|ru|ch|it|nl|se|no|es|mil|ac|kr|an|aq|at|bb|bw|cd|cy|dz|ec|ee|eu|lu|eg|et|fi|gh|gl|gr|hk|ht|hu|ie|il|iq|is|kh|kg|kz|lr|lv|nz|pe|pa|ph|pk|pl|pt|sg|tw|ua|me|tr|cc)(\/[^:\s{}<>±]*|$|(?=[^a-zA-Z]))/gi
  // /https?:\/\/(?:www\.)?[-\w@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-\w()@:%+.~#?&//=]*)/;
  const doneDateRegex =
    /@done\(?:(?:(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[0-1]))(?: (?:(?:[01]\d|2[0-3]):[0-5]\d( ?[aApP][mM])?))?\)/
  const hashTagRegex =
    /(^|\s|["'([{])(?!#[\d\p{P}]+($|\s))([@#]([^\p{P}\s]|[-_/])+?\(.*?\)|[@#]([^\p{P}\s]|[-_/])+)/u
  // /(?!#[\d!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+(\s|$))(#([^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\s]|[-_/])+?\\(.*?\\)|#([^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\s]|[-_/])+)/;
  const atRegex =
    /(^|\s|["'([{])(?!@[\d\p{P}]+($|\s))([@#]([^\p{P}\s]|[-_/])+?\(.*?\)|[@#]([^\p{P}\s]|[-_/])+)/u
  // /(?!@[\d!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+(\s|$))(@([^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\s]|[-_/])+?\\(.*?\\)|@([^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\s]|[-_/])+)/;

  let lexer = new Tokenizr()

  // code
  lexer.rule(/`(?:[^`]*?)`/, (ctx, match) => {
    ctx.accept('code', match[0].substring(1, match[0].length - 1))
  })

  // date link
  lexer.rule(dateLinkRegex, (ctx, match) => {
    ctx.accept('date-link', { name: match[0], href: match[2] })
  })
  // wiki link
  lexer.rule(wikilinkRegex, (ctx, match) => {
    const link = match[0].substring(2, match[0].length - 2)
    ctx.accept('wiki-link', { name: link, href: link })
  })
  // image and file link
  lexer.rule(attachmentLinkRegex, (ctx, match) => {
    ctx.accept('attachment-link', { title: match[1], filename: match[2] })
  })
  // named link
  lexer.rule(namedLinkRegex, (ctx, match) => {
    ctx.accept('named-link', { name: match[1], href: match[2] })
  })
  // link
  lexer.rule(linkRegex, (ctx, _match) => {
    ctx.accept('link')
  })
  // done date
  lexer.rule(doneDateRegex, (ctx, _match) => {
    ctx.accept('done-date')
  })
  // hash tag
  lexer.rule(hashTagRegex, (ctx, match) => {
    // If there was a space, push it back onto the input
    if (match[1] === ' ') {
      ctx.accept('space', match[1])
    }
    ctx.accept('hash-tag', { name: match[3], href: match[3] })
  })
  // at tag
  lexer.rule(atRegex, (ctx, match) => {
    // If there was a space, push it back onto the input
    if (match[1] === ' ') {
      ctx.accept('space', match[1])
    }
    ctx.accept('at-tag', { name: match[3], href: match[3] })
  })

  // bold
  lexer.rule(/\*\*(?:[^*]*?)\*\*/, (ctx, match) => {
    ctx.accept('bold', match[0].substring(2, match[0].length - 2))
  })
  // bold with underscore
  lexer.rule(/__(?:[^_]*?)__/, (ctx, match) => {
    ctx.accept('bold', match[0].substring(2, match[0].length - 2))
  })
  // italic
  lexer.rule(/\*(?:[^*]*?)\*/, (ctx, match) => {
    ctx.accept('italic', match[0].substring(1, match[0].length - 1))
  })
  // italic with underscore, including start of string
  lexer.rule(/(?:^|\b)_(?:[^_]*?)_\b/, (ctx, match) => {
    ctx.accept('italic', match[0].substring(1, match[0].length - 1))
  })
  // strikethrough
  lexer.rule(/~~(?:[^~]*?)~~/, (ctx, match) => {
    ctx.accept('strikethrough', match[0].substring(2, match[0].length - 2))
  })
  // underlined
  lexer.rule(/~(?:[^~]*?)~/, (ctx, match) => {
    ctx.accept('underlined', match[0].substring(1, match[0].length - 1))
  })

  // highlight
  lexer.rule(/==(?:[^=]*?)==/, (ctx, match) => {
    ctx.accept('highlighted', match[0].substring(2, match[0].length - 2))
  })

  // timestring
  lexer.rule(
    /\d{1,2}:\d{2}(?:\s*(?:AM|PM))? - \d{1,2}:\d{2}(?:\s*(?:AM|PM))?/,
    (ctx, match) => {
      ctx.accept('timeString', match[0])
    }
  )

  // plain text
  let plaintext = ''
  lexer.before((ctx, _match, rule) => {
    if (rule.name !== 'text' && plaintext !== '') {
      ctx.accept('text', plaintext)
      plaintext = ''
    }
  })
  lexer.rule(
    /./,
    (ctx, match) => {
      plaintext += match[0]
      ctx.ignore()
    },
    'text'
  )
  lexer.finish((ctx) => {
    if (plaintext !== '') {
      ctx.accept('text', plaintext)
    }
  })

  // The tokenizer has issues with these kind of linebreaks
  let sanitizedInput = text
    .replace(/\u2028|\u2029/g, '%%SOFTBREAK%%')
    .replace('\r', '')

  lexer.input(sanitizedInput)

  let inlineContent: PartialInlineContent[] = []
  lexer.tokens().forEach((token) => {
    // Replace the softbreak token with a newline now
    // Check if token.value is a string, it could be also an object or undefined
    if (token && token.value && typeof token.value === 'string') {
      token.value = token.value.replace(/%%SOFTBREAK%%/g, '\n')
    }

    switch (token.type) {
      case 'text':
        inlineContent.push({ type: 'text', text: token.value, styles: {} })
        break
      case 'bold':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { bold: true },
        })
        break
      case 'italic':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { italic: true },
        })
        break
      case 'strikethrough':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { strikethrough: true },
        })
        break
      case 'underlined':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { underlined: true },
        })
        break
      case 'highlighted':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { highlighted: true },
        })
        break
      case 'timeString':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { timeString: true },
        })
        break
      case 'code':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { code: true },
        })
        break
      case 'attachment-link':
        // Take the attachment's downloadURL from the attachmentList. The attachments are sorted in the same order as they appear in the markdown, except images are sorted before files.
        const path = token.value.filename.split('/')
        let text = decodeURIComponent(path[path.length - 1])

        let attachment: string | null = null
        const isDrawing = token.value.filename.endsWith('.drawing')
        let drawingUrl = undefined

        // Find the right attachment by searching the attachment list if it's a list of objects
        // This is how it works with Supabase. With CloudKit we go by the order of the entries
        // and the order of the file markdown links.
        if (
          attachmentsList.length > 0 &&
          typeof attachmentsList[0] === 'object'
        ) {
          // Find the attachment with the same filename
          attachment = attachmentsList.find(
            (attachment) => attachment.filename === token.value.filename
          )?.url
          text = token.value.title
        } else {
          // Support the CloudKit legacy logic
          const isImage = isFilenameImage(
            token.value.filename,
            token.value.title
          )

          // If the token.value.filename is an image and ends with ".drawing", skip one attachmentIndex, because we save both, the drawing and after that the png version of the same
          if (isImage && isDrawing) {
            // Store the hidden drawing
            // inlineContent.push({
            //   type: "text",
            //   // Get the last path component of the filename
            //   text: text,
            //   styles: { inlineAttachment: true },
            //   attrs: {
            //     filename: token.value.filename,
            //     downloadUrl: attachmentsList[attachmentIndex] ?? undefined,
            //     downloaded: false,
            //     title: token.value.title,
            //     drawingUrl: true,
            //   },
            // });
            drawingUrl = attachmentsList[attachmentIndex] ?? undefined
            attachmentIndex += 1
            // text = text.replace(".drawing", ".png");
            // token.value.filename = token.value.filename.replace(
            //   ".drawing",
            //   ".png"
            // );
          }

          attachment =
            attachmentsList[isImage ? attachmentIndex : attachedNonImageIndex]

          if (isImage) {
            attachmentIndex += 1
          } else {
            attachedNonImageIndex += 1
          }
        }

        // if (attachment) { // UPDATE: allow alos null/undefined attachments, so we can show the loading indicator
        // Get the last split component of the filename

        inlineContent.push({
          type: 'text',
          // Get the last path component of the filename
          text: text,
          styles: { inlineAttachment: true },
          attrs: {
            filename: token.value.filename,
            downloadUrl: attachment ?? undefined,
            downloaded: false,
            title: token.value.title,
            drawingUrl: drawingUrl,
          },
        })
        // }

        break
      case 'link':
        inlineContent.push({
          type: 'link',
          href: token.value,
          content: token.value,
        })
        break
      case 'named-link':
        inlineContent.push({
          type: 'link',
          href: token.value.href,
          content: token.value.name,
        })
        break
      case 'done-date':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: { textColor: 'done-color' },
        })
        break
      case 'hash-tag':
        inlineContent.push({
          type: 'text',
          text: token.value.name,
          styles: { hashtag: true },
          attrs: { href: token.value.href },
        })
        break
      case 'at-tag':
        inlineContent.push({
          type: 'text',
          text: token.value.name,
          styles: { hashtag: true },
          attrs: { href: token.value.href },
        })
        break
      case 'wiki-link':
        inlineContent.push(
          {
            type: 'text',
            text: '[[',
            styles: {},
          },
          {
            type: 'text',
            text: token.value.name,
            styles: { wikilink: true },
            attrs: { href: token.value.href },
          },
          {
            type: 'text',
            text: ']]',
            styles: {},
          }
        )
        break
      case 'date-link':
        inlineContent.push({
          type: 'text',
          text: token.value.name,
          styles: { datelink: true },
          attrs: { href: token.value.href },
        })
        break

      case 'space':
        inlineContent.push({
          type: 'text',
          text: token.value,
          styles: {},
        })
        break
    }
  })

  return inlineContent
}

function parseHeader(
  line: string,
  regex: RegExp,
  level: number
): PartialBlock<DefaultBlockSchema> | null {
  let matches = regex.exec(line)

  if (matches == null) {
    return null
  }

  let substring = line.substring(matches[0].length)

  // let header = createElement(type, substring);
  // header.id = generateHeaderID(substring);
  // TODO use as BlockIdentifier?
  let header = createBlock('heading' as BlockType, substring, {
    level: level.toString(),
  })

  return header
}

function parseIndentedBlock(
  line: string,
  regex: RegExp,
  type: BlockType
): PartialBlock<DefaultBlockSchema> | null {
  let matches = regex.exec(line)

  if (matches == null) {
    return null
  }

  let substring = line.substring(matches[0].length)
  let block = createBlock(type, substring)

  return block
}

function indentationLevel(string: string): number {
  // Get the indentation level. First replace 4 whitespaces with tabs and then remove anything that's not a tab
  let leadingWhitespace = string.replace(/ {4}/g, '\t').replace(/[^\t ]/g, '')

  // Count the number of tabs in the leadingWhitespace string.
  let tabsCount = leadingWhitespace
    ? leadingWhitespace.split('\t').length - 1
    : 0

  return tabsCount
}

function flagLevel(string: string): number {
  let flaggedMatches = /^\s*(!{1,})\s+.*$/.exec(string)
  if (flaggedMatches == null) {
    return 0
  }

  let flags = flaggedMatches[1]
  return flags.length
}

function parseParagraph(line: string): PartialBlock<DefaultBlockSchema> {
  if (line.length === 0) {
    return createBlock('paragraph' as BlockType, '')
  }

  const matches = /^(\s*)(.*)$/.exec(line)
  if (matches != null) {
    const level = indentationLevel(matches[1])
    const content = matches[2]

    if (level > 0) {
      return createBlock('paragraph' as BlockType, content, {
        level: level.toString(),
      })
    }
  }

  return createBlock('paragraph' as BlockType, line)
}

function parseHeader1(line: string): PartialBlock<DefaultBlockSchema> | null {
  return parseHeader(line, /^#\s+/, 1)
}

function parseHeader2(line: string): PartialBlock<DefaultBlockSchema> | null {
  return parseHeader(line, /^##\s+/, 2)
}

function parseHeader3(line: string): PartialBlock<DefaultBlockSchema> | null {
  return parseHeader(line, /^###\s+/, 3)
}

function parseHeader4(line: string): PartialBlock<DefaultBlockSchema> | null {
  return parseHeader(line, /^#+\s+/, 4)
}

function parseSeparator(line: string): PartialBlock<DefaultBlockSchema> | null {
  return parseIndentedBlock(
    line,
    /^\s*([-*]\s*){3,}$/,
    'separator' as BlockType
  )
}

/// Start of Selection
function parseTable(line: string): PartialBlock<DefaultBlockSchema> | null {
  // Check if the line starts with %%%npTableData:
  if (!line.startsWith(encodedTablePrefix)) {
    return null
  }

  // Remove the %%%npTableData: from the beginning
  line = line.slice(encodedTablePrefix.length)

  // Convert the line (JSON string) into an object
  const tableData: any = JSON.parse(line)
  const rows: PartialBlock<DefaultBlockSchema>[] = []

  for (const [index, rowData] of tableData.entries()) {
    const cells: PartialBlock<DefaultBlockSchema>[] = []

    for (const cell of rowData) {
      const cellBlock: PartialBlock<DefaultBlockSchema> = {
        type: index === 0 ? 'tableHeaderCellBlockItem' : 'tableCellBlockItem',
        content: cell,
        props: { isChildBlock: true },
      }
      cells.push(cellBlock)
    }

    const row: PartialBlock<DefaultBlockSchema> = {
      type: 'tableRowBlockItem',
      children: cells,
      props: { isParentBlock: true }, // This is needed for block to node conversions
    }

    rows.push(row)
  }

  // Create the final table
  const tableBlock: PartialBlock<DefaultBlockSchema> = {
    type: 'tableBlockItem',
    children: rows,
    props: { isParentBlock: true }, // This is needed for block to node conversions
  }

  return tableBlock
}

function parseCodeFence(line: string): PartialBlock<DefaultBlockSchema> | null {
  // Trim line so we prevent issues with spaces or tabs at the beginning
  if (!line.trim().startsWith(encodedCodeFencePrefix)) {
    return null
  }

  // Remove the %%%npTableData: from the beginning
  line = line.trim().substring(encodedCodeFencePrefix.length)

  try {
    const data = JSON.parse(line)
    return createBlock('codefence' as BlockType, data.code, {
      language: data.language,
    })
  } catch (e) {
    return null
  }
}

function parseQuote(line: string): PartialBlock<DefaultBlockSchema> | null {
  let matches = /^(\s*?)>\s+/.exec(line)

  if (matches == null) {
    return null
  }

  let substring = line.substring(matches[0].length)
  let block = createBlock('quoteListItem', substring, {
    level: indentationLevel(matches[1]),
  })

  return block
}

function parseListWithIcon(
  line: string,
  regex: RegExp,
  type: BlockType,
  props?: Props<PropSchema>
): PartialBlock<DefaultBlockSchema> | null {
  let matches = regex.exec(line)

  if (matches == null) {
    return null
  }

  let substring = line.substring(matches[0].length)
  let level = indentationLevel(matches[1])
  let flagged = flagLevel(substring)

  if (props?.checked || props?.scheduled || props?.cancelled) {
    flagged = 0
  }

  let block = createBlock(type, substring, {
    ...props,
    level: level.toString(),
    flagged: flagged,
  })
  return block
}

function parseOrderedList(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  let matches = /^(\s*?)(\d+)\.\s+/.exec(line)

  if (matches == null) {
    return null
  }

  let substring = line.substring(matches[0].length)
  let level = indentationLevel(matches[1])

  let block = createBlock('numberedListItem' as BlockType, substring, {
    level: level.toString(),
    index: matches[2],
  })
  return block
}

function parseToDoDash(line: string): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)- \[ \]\s+/,
    'taskListItem' as BlockType
  )
}

function parseToDoDashCancelled(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)- \[-\]\s+/,
    'taskListItem' as BlockType,
    {
      cancelled: true,
    }
  )
}

function parseToDoDashComplete(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)- \[x\]\s+/,
    'taskListItem' as BlockType,
    {
      checked: true,
    }
  )
}

function parseToDoDashScheduled(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)- \[>\]\s+/,
    'taskListItem' as BlockType,
    {
      scheduled: true,
    }
  )
}

function parseToDoAsteriskCancelled(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\* \[-\]\s+/,
    'taskListItem' as BlockType,
    {
      cancelled: true,
    }
  )
}

function parseToDoAsteriskComplete(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\* \[x\]\s+/,
    'taskListItem' as BlockType,
    {
      checked: true,
    }
  )
}

function parseToDoAsteriskScheduled(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\* \[>\]\s+/,
    'taskListItem' as BlockType,
    {
      scheduled: true,
    }
  )
}

function parseToDoAsterisk(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\* \[ \]\s+/,
    'taskListItem' as BlockType
  )
}

function parseChecklistPlusCancelled(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\+ \[-\]\s+/,
    'checkListItem' as BlockType,
    {
      cancelled: true,
    }
  )
}

function parseChecklistPlusComplete(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\+ \[x\]\s+/,
    'checkListItem' as BlockType,
    {
      checked: true,
    }
  )
}

function parseChecklistPlusScheduled(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\+ \[>\]\s+/,
    'checkListItem' as BlockType,
    {
      scheduled: true,
    }
  )
}

function parseChecklistPlus(
  line: string
): PartialBlock<DefaultBlockSchema> | null {
  return parseListWithIcon(
    line,
    /^(\s*?)\+( \[ \])?\s+/,
    'checkListItem' as BlockType
  )
}

function parseTodoList(
  line: string,
  taskItemCharacters: string[]
): PartialBlock<DefaultBlockSchema> | null {
  if (taskItemCharacters.length === 0) {
    return null
  }
  const regex = new RegExp(`^(\\s*?)[${taskItemCharacters.join('')}]\\s+`)
  return parseListWithIcon(line, regex, 'taskListItem' as BlockType)
}

function parseUnorderedList(
  line: string,
  bulletItemCharacters: string[]
): PartialBlock<DefaultBlockSchema> | null {
  if (bulletItemCharacters.length === 0) {
    return null
  }
  const regex = new RegExp(`^(\\s*?)[${bulletItemCharacters.join('')}]\\s+`)
  return parseListWithIcon(line, regex, 'bulletListItem' as BlockType)
}

function preParseMarkdownCodeFencesIntoSingleLines(markdown: string): string {
  // Detect code fences and write them into a single line
  const codeFenceRegex = /```(.*)\n([\s\S]*?)\n```/gm
  let match

  while ((match = codeFenceRegex.exec(markdown)) !== null) {
    const codeFence = match[0]
    const language = match[1]
    const code = match[2]

    // Create a json string that we can parse later with langauge and code and type
    const json = JSON.stringify({
      language,
      code,
    })
    markdown = markdown.replace(codeFence, encodedCodeFencePrefix + json)
  }

  return markdown
}

// Converts the multi-line table into a one-liner, so we can pick it up in the line-by-line processing
function preParseMarkdownTablesIntoSingleLines(markdown: string): string {
  const lines = markdown.split(/\r?\n/)
  let i = 0
  const outputLines: string[] = []

  while (i < lines.length) {
    if (isLikelyTableLine(lines[i])) {
      // Collect all subsequent lines that also seem like a table row.
      let tableChunk = lines[i]
      i++
      while (i < lines.length && isLikelyTableLine(lines[i])) {
        tableChunk += '\n' + lines[i]
        i++
      }
      // Convert multi-line table block into single line:
      const singleLineTable = combineTableLines(tableChunk)
      outputLines.push(singleLineTable)
    } else {
      outputLines.push(lines[i])
      i++
    }
  }

  return outputLines.join('\n')
}

// A minimal check so only lines starting & ending with "|"
// (with more than one "|" overall) are recognized as table lines.
function isLikelyTableLine(line: string): boolean {
  const trimmed = line.trim()
  if (!trimmed.startsWith('|') || !trimmed.endsWith('|')) return false
  // Count pipes: needs at least two to have multiple columns.
  const pipeCount = (trimmed.match(/\|/g) || []).length
  return pipeCount > 1
}

/**
 * Combines multiple lines of a table block into one line
 * and encodes them for re-parsing line-by-line below.
 */
function combineTableLines(tableChunk: string): string {
  // Split the chunk by lines
  let rows = tableChunk.trim().split(/\r?\n/)
  // Combine lines that don’t begin with '|', so any stray bullet points or dashes
  // get appended to the previous cell.
  let combinedRows: string[] = []
  let i = 0

  while (i < rows.length) {
    let currentRow = rows[i]
    i++
    while (i < rows.length && !rows[i].trim().startsWith('|')) {
      currentRow += ' ' + rows[i].trim()
      i++
    }
    combinedRows.push(currentRow)
  }

  // Now convert combined rows into an array-of-arrays table
  const headerCells = combinedRows[0]
    .split('|')
    .map((cell) => cell.trim())
    .slice(1, -1)

  const tableRows = []
  for (let j = 1; j < combinedRows.length; j++) {
    const cells = combinedRows[j]
      .split('|')
      .map((cell) => cell.trim())
      .slice(1, -1)

    // Skip lines that only contain dashes (e.g. "----" or "------")
    if (cells.every((cell) => /^-+$/.test(cell))) {
      continue
    }

    tableRows.push(cells)
  }

  // Create JSON data for the line
  const tableData = [headerCells, ...tableRows]
  const encodedTableData = encodedTablePrefix + JSON.stringify(tableData)
  return encodedTableData
}

export function convertMarkdownLineToBlock(
  line: string,
  bulletItemCharacters: string[],
  taskItemCharacters: string[]
): PartialBlock<DefaultBlockSchema> {
  const content = line.replace('\r', '')

  // Replace traditional for loop with for-of loop
  for (const parseFunction of parseFunctions) {
    const block = parseFunction(content)
    if (block !== null) {
      return block
    }
  }

  const todoListBlock = parseTodoList(content, taskItemCharacters)
  if (todoListBlock !== null) {
    return todoListBlock
  }
  const unorderedListBlock = parseUnorderedList(content, bulletItemCharacters)
  if (unorderedListBlock !== null) {
    return unorderedListBlock
  }
  const paragraphBlock = parseParagraph(content)
  if (paragraphBlock !== null) {
    return paragraphBlock
  }

  // nothing matched, return a paragraph
  return createBlock('paragraph' as BlockType, content)
}

function postProcessBlocks(blocks: PartialBlock<BlockSchema>[]): void {
  let blocksLength = blocks.length
  // go through all blocks and nest blocks with lower level into previous blocks
  for (let i = 1; i < blocksLength; ++i) {
    let currentBlock = blocks[i]
    let previousBlock = blocks[i - 1]

    if (
      [
        'bulletListItem',
        'taskListItem',
        'numberedListItem',
        'checkListItem',
        'quoteListItem',
        'paragraph',
      ].includes(currentBlock.type || '') &&
      [
        'bulletListItem',
        'taskListItem',
        'numberedListItem',
        'checkListItem',
        'quoteListItem',
        'paragraph',
      ].includes(previousBlock.type || '')
    ) {
      const previousLevel = previousBlock.props?.level || 0
      const currentLevel = currentBlock.props?.level || 0
      if (previousBlock.children && currentLevel > previousLevel) {
        previousBlock.children.push(currentBlock)
        blocks.splice(i, 1)
        --blocksLength
        --i
      }
      postProcessBlocks(previousBlock.children || [])
    }
  }
}

export function convertMarkdownToBlocks(
  note: string,
  attachments: string,
  bulletItemCharacters: string[],
  taskItemCharacters: string[]
) {
  if (attachments && attachments.length > 0) {
    try {
      attachmentsList = JSON.parse(attachments)
    } catch {
      attachmentsList = []
    }

    // Check if the entries are strings or json objects
    try {
      attachmentsList = attachmentsList.map((attachment) => {
        return JSON.parse(attachment)
      })
    } catch {
      // Do nothing
    }
  }

  if (!note) {
    return []
  }

  // File attachments are sorted after the images, so count all the images first, so we get the right file index
  attachedNonImageIndex = (note.match(imageLinkRegex) || []).length
  attachmentIndex = 0

  // Parse first the multi-line blocks like code-fences and tables into a single line, so we can process it later line by line
  note = preParseMarkdownTablesIntoSingleLines(note)
  note = preParseMarkdownCodeFencesIntoSingleLines(note)

  let lines = note.split(/\r?\n/)
  const linesCount = lines.length
  let blocks = []

  for (let i = 0; i < linesCount; i++) {
    blocks.push(
      convertMarkdownLineToBlock(
        lines[i],
        bulletItemCharacters,
        taskItemCharacters
      )
    )
  }
  postProcessBlocks(blocks)
  return blocks
}
