import { ExternalCalendarSection } from './ExternalCalendarSection'
import { useIsAuthenticated } from '@azure/msal-react'
import { useOutlookCalendars } from '../../../hooks/OutlookCalendar'
import { signin, signout } from '../../../lib/msal/authConfig'

export function OutlookCalendarSection() {
  const isAuthenticated = useIsAuthenticated()
  const { data: outlookCalendars = [] } = useOutlookCalendars()

  return (
    <ExternalCalendarSection
      title='Outlook'
      calendars={outlookCalendars}
      isSignedIn={isAuthenticated}
      onSignIn={signin}
      onSignOut={signout}
    />
  )
}
