import { useState } from 'react'
import { SignInAppleDialog } from '../SignInAppleDialog'
import { useNotification } from '../../../providers/NotificationProvider'
import {
  useAppleCalDAVSignout,
  useAppleCalendars,
} from '../../../hooks/AppleCalDAV'
import { ExternalCalendarSection } from './ExternalCalendarSection'
import { errorIcon } from '../../../components/Notification'

export function AppleCalendarSection() {
  const [showSignInAppleDialog, setShowSignInAppleDialog] =
    useState<boolean>(false)
  const { data: appleCalendars = [] } = useAppleCalendars()
  const hasAppleCalendars =
    Array.isArray(appleCalendars) && appleCalendars.length > 0
  const signout = useAppleCalDAVSignout()
  const { showNotification } = useNotification()

  function handleAppleSignout() {
    signout.mutate(undefined, {
      onSuccess: () => {
        showNotification({
          title: 'Apple Calendar',
          message: 'Successfully signed out from Apple Calendars',
        })
      },
      onError: (registerError) => {
        showNotification({
          title: 'Apple Calendar',
          message: registerError.response?.data.error ?? 'An error occurred',
          icon: errorIcon,
        })
      },
    })
  }

  return (
    <ExternalCalendarSection
      title='Apple'
      calendars={appleCalendars}
      isSignedIn={hasAppleCalendars}
      onSignIn={() => {
        setShowSignInAppleDialog(true)
      }}
      onSignOut={handleAppleSignout}
      dialog={
        <SignInAppleDialog
          open={showSignInAppleDialog}
          setOpen={setShowSignInAppleDialog}
        />
      }
    />
  )
}
