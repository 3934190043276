import { useGoogleLogin } from '@react-oauth/google'
import {
  useSignIn,
  useSignOut,
  useAccessToken,
  useGoogleCalendars,
} from '../../../hooks/GoogleCalendar'
import { useNotification } from '../../../providers/NotificationProvider'
import { errorIcon } from '../../../components/Notification'
import { ExternalCalendarSection } from './ExternalCalendarSection'

export function GoogleCalendarSection() {
  const { data: accessToken } = useAccessToken()
  const { data: googleCalendars = [] } = useGoogleCalendars()
  const signin = useSignIn()
  const googleSignin = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    ux_mode: 'popup',
    scope:
      'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
    onSuccess: ({ code }) => {
      signin.mutate(
        { code },
        {
          onSuccess: () => {
            showNotification({
              title: 'Google Calendar',
              message: 'Sign in successfully',
            })
          },
        }
      )
    },
    onError: ({ error_description }) => {
      showNotification({
        title: 'Google Calendar',
        message: error_description ?? '',
        icon: errorIcon,
      })
      handleGoogleSignout()
    },
  })
  const googleSignout = useSignOut()
  const { showNotification } = useNotification()

  function handleGoogleSignin() {
    googleSignin()
  }

  function handleGoogleSignout() {
    googleSignout.mutate(undefined, {
      onSuccess: () => {
        showNotification({
          title: 'Google Calendar',
          message: 'Sign out successfully',
        })
      },
    })
  }

  return (
    <ExternalCalendarSection
      title='Google'
      calendars={googleCalendars}
      isSignedIn={!!accessToken?.token}
      onSignIn={handleGoogleSignin}
      onSignOut={handleGoogleSignout}
    />
  )
}
